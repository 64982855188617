<template>
	<div>
		<Transition :name="slideDirection">
			<DisclosurePanel
				:class="[
					justifyLeft ? 'xs:right-0 right-[30%]' : 'shadow-left xs:left-0 left-[30%]',
					backgroundColor,
					'fixed top-0 xs:w-full w-[70%] h-screen md:hidden z-50'
				]"
			>
				<DisclosureButton
					:class="[
						justifyLeft ? 'right-2' : 'left-2',
						buttonColor,
						'absolute z-50 rounded-md top-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-mx-orange'
					]"
				>
					<span class="sr-only">
						Close main menu
					</span>
					<XMarkIcon
						class="block w-8 h-8"
						aria-hidden="true"
					/>
				</DisclosureButton>
				<slot name="header" />
				<slot name="content" />
			</DisclosurePanel>
		</Transition>
		<TransitionChild
			as="template"
			enter="ease-in-out duration-500"
			enter-from="opacity-0"
			enter-to="opacity-100"
			leave="ease-in-out duration-500"
			leave-from="opacity-100"
			leave-to="opacity-0"
		>
			<div
				:class="[{ 'transition-opacity bg-gray-500 bg-opacity-75': showBackgroundOverlay }, 'fixed inset-0']"
			>
				<DisclosureButton class="w-full h-screen cursor-default" />
			</div>
		</TransitionChild>
	</div>
</template>

<script setup lang="ts">
import { toRefs, computed } from 'vue'
import { DisclosureButton, DisclosurePanel, TransitionChild } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
	justifyLeft: {
		type: Boolean,
		default: false
	},
	backgroundColor: {
		type: String,
		default: 'bg-mx-navy'
	},
	showBackgroundOverlay: {
		type: Boolean,
		default: false
	}
})

const { justifyLeft, backgroundColor } = toRefs(props)

const slideDirection = computed(() => {
	return justifyLeft.value ? 'slide-right' : 'slide-left'
})

const buttonColor = computed(() => {
	return backgroundColor.value === 'bg-white' ? 'text-gray-500' : 'text-white'
})

</script>
